import React from "react"
import Template from "../../templates/capabilities"
import { useStaticQuery, graphql } from "gatsby"
import { CAPABILITIES } from "../../config"

const Page = props => {
  return <Template {...props} options={CAPABILITIES["finance"]}></Template>
}

export default props => {
  const data = useStaticQuery(graphql`
    query {
      imageThree: file(relativePath: { eq: "banners/acquisition.png" }) {
        childImageSharp {
          fluid(maxWidth: 1180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const imageThree = data.imageThree.childImageSharp.fluid
  const images = [imageThree]
  return <Page {...props} images={images} />
}
